import styled from 'styled-components';

const RegularFont = 'Rubik';
const MediumFont = 'Rubik-Medium';
const MainContentContainer = styled.div`
  width: calc(100% - 34px);
  font-family: ${RegularFont};
  width: 100%;
    height: 1600px;
    padding: 25px;
  > div {
  justify-content: center;
  display: flex;
  }
  max-width: 1356px;
  @media (max-width: 1100px) {
    max-width: 1015px;
  }
`;
const Activities = styled.div`
  float: left;
  width: 65%;
  margin-right: 25px;
  max-width: 835px;
  @media (max-width: 500px) {
    width: 100%;
  }
  > div: last-child {
    float: left;
    width: 100%;
    margin-top: 10px;
    > div: first-child {
      width: calc((100% - 25px) / 2);
      margin-right: 12px;
      float: left;

      @media (max-width: 1200px) {
        width: 100%;
      }
    }
    > div: last-child {
      width: calc((100% - 25px) / 2);
      float: left;
      margin-left: 13px;

      @media (max-width: 1200px) {
        width: 100%;
      }
    }
  }
  @media (max-width: 767px) {
    width: 100%;
  }

  @media (min-width: 767px) and (max-width: 1200px) {
    width: 60%;
  }
`;

const SidebarContainer = styled.div`
  width: calc(35% - 25px);
    max-width: 385px;
    float: left;
  @media (max-width: 767px) {
    width: 100%;
    margin-top: 20px;
  }

  @media (min-width: 767px) and (max-width: 1200px) {
    width: calc(40% - 20px);
  }
`;

const Container = styled.div`
  display: inline-block;
  width: ${({ behaviorChallenge }) => behaviorChallenge ? '100%' : 'calc((100%/3) - 80px);'};
  background-color: white;
  float: left;
  box-shadow: 1px 1.732px 46px rgba(0,0,0,0.18);
  margin-right: 20px;
  margin-top: ${({ behaviorChallenge }) => !behaviorChallenge && '20px'};
  height: ${({ fixedHeight }) => fixedHeight ? '450px' : 'auto'};

  &:last-child {
    margin-right: 0;
  }
  
  @media(max-width: 1200px) {
    width: 100%;
    height: auto;
  }
`;
const ContainerV2 = styled(Container)`
  width: ${({width}) => width ? width : 'calc(62% - 15px)'};
  margin-right: ${({marginRight}) => marginRight ? marginRight : '0px'};
  background: ${({background}) => background ? '#FFFFFF' : 'transparent'};
  margin-top: ${({marginTop}) => marginTop ? marginTop : '30px'};
  box-shadow: none;
  height: ${({height}) => height ? height : 'unset'};
  margin-bottom: ${({background}) => background && '10px'};
  padding-bottom: ${({background}) => background && '30px'};
  border-radius: ${({background}) => background && '6px'};
`;
const SlideShowContainer = styled.div`
   @media(min-width: 1200px) {
    display: none;
   }
  .slick-slide.carousel-item {
    opacity: 0.5;
    &.slick-active {
      opacity: 1;
      &.slick-center {
        opacity: 0.5;
      }
    }
  }
  .slick-slider {
    width: 100%;
    margin: 0 0 15px;
    .slick-slide{
      margin: 0px 2px 0px 0px;
      @media (max-width: 600px){
        margin: 0 auto;
      }
    }
    .slick-prev {
      left: -10px;
      position: absolute;
      top: 50%;
      right: -52px;
      z-index: 1;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 1.375rem;
    }
    .slick-prev:before {
      font-family: "Rubik";
      font-size: 42px;
      line-height: 1;
      color: #4798D9;
      margin: 0 15px;
      opacity: 0.75;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transition: left .3s ease;
      }
      .slick-prev:before {
        content: "‹";
      }
      [dir="rtl"] .slick-prev:before {
        content: "›";
      }
    .slick-next {
        left: unset;
        position: absolute;
        top: 50%;
        right: -15px;
        z-index: 1;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 1.375rem;
      }
      .slick-next:before {
        content: "›";
        font-family: "Rubik";
        font-size: 42px;
        line-height: 1;
        color: #4798D9;
        margin: 0 18px;
      }
      [dir="rtl"] .slick-next:before {
        content: "‹";
      }
  }
`;
const  SlideShowCard = styled.div`
  width: ${({fullWidth}) => fullWidth ? 'calc((100% - 10px) / 3)' : '95%'};
  border-radius: 6px;
  display: flex;
  margin: 0 7px;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 200px;
  .bg-opacity {
    position: absolute;
    height: 200px;
    width: ${({fullWidth}) => fullWidth ? '100%' : '95%'};
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    @media (max-width: 600px){
      width: 100%;
    }
  }
  .slideName{
    position: absolute;
    font-family: ${MediumFont}
    font-weight: 500;
    font-size: 15px;
    color: #FFFFFF;
    text-transform: capitalize;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 81%;
    text-align: center;
  }
  @media (max-width: 600px){
    width: 100%;
  }
`;
const ImageContainer = styled.div`
  width: ${({fullWidth}) => fullWidth ? '100%' : '95%'};
  display: inline-block;
  position:relative
  height: 200px;
  > img {
    cursor: default
    height: 200px;
    border-radius: 6px;
    width: 100%;
  }
  @media (max-width: 600px){
    width: 100%;
  }
`;
const MyFocusContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > div: first-child {
    margin-right: 45px;
  }
  > div{
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-basis: 64px;
    margin-right: 10px;
    & > div:last-child {
      margin-right: 0px;
    }
    >img{
      width: 48px;
      height: 48px;
      cursor: pointer; 
    }
    > div {
      color: rgba(38, 38, 43, 0.7);
      font-family: ${MediumFont};
      letter-spacing: 1px;
      text-align: center;
      margin: 10px 0;
      min-height: 32px;
      font-size: 12px;   
      padding: 2px;
    }
  }
`;
const DailyTipHeader = styled.div`
  color: ${({color}) => color ? '#8BBD53' : '#FF6D4A'};
  font-family: ${MediumFont};
  font-size: 18px;
  padding: 25px 30px 0px 30px;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
`;
const WellnessGoalContainer = styled.div`
  width: ${({width}) => width ? '100%' : 'calc((100%/3) - 14px)'};
  float: left;
  background-color: #FFFFFF;
  margin-top: 20px;
  border-radius: 6px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-right: ${({width})=> width ? '0px' : '20px'};
`;
const SlideDisabled = styled.div`
  display: flex;
  margin: 0 0px 15px 0px;
  width: 100%;
  @media(max-width: 1200px) {
    display: none;
  }
`;
const HeaderContainer =styled.div`
width: 100%;
display: flex;
justify-content: flex-end;
align-items: center;
background-color: #FFF;
border-top: 1px solid #F6F6F6;
padding: 10px;

  > div {
    font-family: ${MediumFont};
    font-size: 18px;
    color: rgba(0,47,71,1);
  }
  > div: first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    >img{
      width: 10px;
      height: 20px;    outline: none;
      margin-right: 20px;
    }
    @media(max-width: 450px) {
      margin-right: 15px;
      margin-bottom: 15px;
    }
  }

  > div: last-child {
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 24px;
      height: 24px;
      margin-right: 25px;
      display: none;
    }
    > div {
      > button {
        font-family: ${MediumFont};
        font-size: 15px;
        background-color: rgb(253,113,117);
        color: #FFF;
        width: 87%;
        padding: 4px;
        border: none;
        border-radius: 4px;
        display: flex;
        justify-content: stretch;
        height: 45px;
        outline: none;
        > img {
          width: 20%;
          height: 100%;
          margin-right: 12px;
          float: left;
        }
        > span {
          float: right;
          align-self: center;
        }
      }
    }
  }
  @media (max-width: 380px) {
    flex-flow: column;
    align-items: flex-start;
  }
`;
const ConnectDevice = styled.div`
width: 100%;
height: 51px;
margin-bottom: 25px;
> button {
  width: 100%;
  height: 100%;
  font-family: ${MediumFont};
  font-size: 15px;
  background-color: ${(props) => props.bgcolor ? " #69C2FF" : "rgb(253,113,117)"};
  color: #FFF;
  padding: 4px;
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: stretch;
	outline: none;
	> div: first-child {
		padding: 5px;
		width: auto;
    height: 100%;
    background-color: #FFF;
    border-radius: 3px;
		> img {
			height: 100%;
			float: left;
		}
	}
  > div:last-child{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0%;
    > span {
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      text-align: right;
      color: #FFFFFF;
    }
  }
}

@media(max-width: 766px){
  > button {
    > img {
      width: 8%;
    }
    > div {
      justify-content: center;
      align-items: center;
      padding-left: 0%;
    }
  }
}
@media(max-width: 500px){
  > button {
    > img {
      width: 12%;
    }
    > div {
      justify-content: center;
      align-items: center;
      padding-right: 25%;
      padding-left: 14%;
    }
  }
}
`;

export { MainContentContainer, Activities, Container, SidebarContainer, SlideShowContainer, ImageContainer, SlideShowCard, ContainerV2, MyFocusContainer, DailyTipHeader, WellnessGoalContainer, SlideDisabled, HeaderContainer,
  ConnectDevice
};