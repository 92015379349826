import styled, { css } from 'styled-components';

import { TitleContent } from '../styles';
const MediumFont = 'Rubik-Medium';
const TitleContainer = styled(TitleContent)`
  padding: 20px;
  border-bottom: ${({ noBorder }) => noBorder ? 'none' : '1px solid #e2e2e4'};
`;

const VideoContainer = styled.div`
  width: 100%;
  float: left;
  padding: 12px 0;
`;

const VideoPlayer = styled.div`
  width: 100%;
  float: left;
  height: ${({ height }) => height ? height : '250px'};
  text-align: center;
  cursor: pointer;
  position: relative;
  
  > div {
    width: 100% !important;
    height: 100% !important;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: ${({radius}) => radius && '6px'};
    @media (max-width: 1200px) {
      width: auto;
    }
  }

  ${({ wellnessDashboard }) => wellnessDashboard && css`
    img {
      max-width: 400px;
    }
  `}
  @media (max-width: 500px){
    height: 200px;
  }
  .title{
    font-size: 18px;
    text-transform: Capitalize;
    width: 100%;
    float: left;
    text-align: left;
    position: absolute;
    font-family: ${MediumFont};
    top: 25px;
    left: 35px;
    color: #FFFFFF;
    width: auto !important;
    height: unset !important;
  }
  .bg-opacity {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.329545);
    border-radius: 6px;
  }
  .getStarted{
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    width: 122px;
    height: 37px;
    background-color: #FD7175;
    // border: 1px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    color: white;
  }
  @media (max-width: 1200px) {
    width: 100%;
    float: left;
    height: ${({ height }) => height ? height : '250px'};
    text-align: center;
    cursor: pointer;
    position: relative;
    
    > div {
      width: 100% !important;
      height: 100% !important;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: ${({radius}) => radius && '6px'};
      width: auto;
    }
    ${({ wellnessDashboard }) => wellnessDashboard && css`
    img {
      max-width: 400px;
    }
  `}
  .title{
    font-size: 18px;
    text-transform: Capitalize;
    width: 100%;
    float: left;
    text-align: left;
    position: absolute;
    font-family: ${MediumFont};
    top: 25px;
    left: 35px;
    color: #FFFFFF;
    width: auto !important;
    height: unset !important;
  }
  .bg-opacity {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.329545);
    border-radius: 6px;
  }
  .getStarted{
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    width: 122px;
    height: 37px;
    background-color: #FD7175;
    // border: 1px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    color: white;
  }
  }
`;

const VideoTitle = styled.div`
  float: left;
  text-transform: capitalize;
  margin-left: 5px;
  margin-top: 10px;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: rgb(51, 51, 51);
  font-family: Rubik-Bold;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const PlayIcon = styled.p`
  width: 66px;
  height: 66px;
  background-color: #000000;
  border-radius: 50%;
  vertical-align: middle;
  position: absolute;
  left: calc(50% - 33px);
  top: calc(50% - 33px);
  opacity: ${({opacity}) => opacity ? '1' : '0.5'};

  > i {
    color: white;
    font-size: 20px;
    margin-left: 3px;
  }

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`;

const DailyEducationContainer = styled.div`
  padding: 20px 0;
  float: left;
  width: 100%;
`;

const ButtonLearning = styled.div`

  position: absolute;
  top: 0;
  width: 100%;
  height: 250px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px 6px 0 0;
  .getStarted {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    width: 122px;
    height: 37px;
    background-color: #FD7175;
    // border: 1px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    color: white;
  }
  `;

export { VideoTitle, TitleContainer, VideoContainer, VideoPlayer, DailyEducationContainer, PlayIcon, ButtonLearning };
