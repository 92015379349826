import React from 'react';
import PropTypes from 'prop-types';
import { StyledBody } from '../InputActivity/styles';
import { StyledModal, ImageContainerPopup, PopupHeading, PopupBody, StartButtonContainer, AppButtonsContainer } from './styles';
const WelcomeModal  = ({ showModal, name, closeModal }) => (
  <StyledModal show={showModal}>
    <ImageContainerPopup >
      <div>
        <img src="/public/images/welcomePopupBanner.png" alt="welcomePopup" />
      </div>
    </ImageContainerPopup>
    <StyledBody>
      <PopupHeading>Welcome, {name}!</PopupHeading>
      <PopupBody><span className='mobileViewHidden'>
        We’re glad to have you onboard. Woliba offers many educational <br />
        resources, wellness challenges, fitness and recipe videos, and<br />
        daily tips to help you reach your health and wellness goals. 💥  <br />
         Woliba is here to help you on your Wellbeing Journey and <br />
          work towards your wellness goals. 👏🏻<br />
          Don’t forget to download our iOS or Android app. <br />
          Welcome to the Woliba family. 😁</span>
      <span className='mobileViewText'>
        We’re glad to have you onboard. Woliba offers many educational resources, wellness challenges, fitness and recipe videos, and daily tips to help you reach your health and wellness goals. 💥
      </span>  
      </PopupBody>
      <StartButtonContainer className='mobileViewHidden' onClick={() => closeModal(false)}>
        <button>
          {"Let's Get Started"}
        </button>
      </StartButtonContainer>
      <AppButtonsContainer>
        <div>Download The App Now<br />
          To Start Your Wellness Journey.</div>
        <div> <a href='https://apps.apple.com/in/app/woliba/id1072665057'><img src='/public/images/App_store.png' ></img></a></div>
        <div> <a href='https://play.google.com/store/apps/details?id=com.trainingamigo.tudip.trainingamigo&hl=en_IN&gl=US&pli=1'><img src='/public/images/Playstore.png' ></img></a></div>

      </AppButtonsContainer>
    </StyledBody>
  </StyledModal>
);

WelcomeModal.propTypes = {
  showModal: PropTypes.bool,
  name: PropTypes.string,
  closeModal: PropTypes.func
};

export default WelcomeModal;